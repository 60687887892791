.main {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  
  position: relative;
  // top: -100px;
  z-index: 90;
  height: 110%;

  display: flex;
  justify-content: space-between;

  align-items: center;
  overflow: hidden;

  &__backgrounds-left {
    position: absolute;
    left: 0;

    z-index: 99;
  }

  &__top {
    position: absolute;
    top: -100px;
    z-index: 99;
    left: 180px;
  }

  &__backgrounds-bg {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 110%;
    bottom: 200px;
  }

  &__container {
    padding: 0 23px;
    max-width: 1160px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  
    align-items: center;
   
  }

  &__text {
    top: -100px;
    max-width: 497px;
    width: 100%;
    position: relative;
    z-index: 99;
    padding-left: 24px;
  }

  &__title {
    font-family: Gilroy;
    font-size: 130px;
    font-weight: 700;
    line-height: 120px;
    text-transform: uppercase;
    background-image: linear-gradient(93deg, #FFFDFD -1.18%, #FFF 92.19%);
    background-clip: text;
    color: transparent;

    & span {
      background: linear-gradient(93deg, #FFFDFD -1.18%, #FFF 92.19%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: Gilroy;
      font-size: 105px;
      font-style: normal;
      font-weight: 700;
      line-height: 90px;
      text-transform: uppercase; 
    }

    &.come-tomorrow {
      color: #FFF;

      text-shadow: 0px 4px 15.600000381469727px rgba(255, 170, 0, 0.49);
      font-family: Gilroy;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 109.1%; /* 60.005px */
      letter-spacing: 0.55px;
      text-transform: uppercase;

      margin-bottom: 100px;
      max-width: 475px;
      width: 100%;

    }
  }

  &__subtitle {
    font-family: Gilroy;
    font-size: 34px;
    font-weight: 700;
    line-height: 109.1%; 
    letter-spacing: 0.34px;
    text-transform: uppercase;
    background-image: linear-gradient(93deg, #FFFDFD -1.18%, #FFF 92.19%);
    background-clip: text;
    color: transparent;

    margin-bottom: 53px;


    &.come-tomorrow {
      border-radius: 10px;
      background: linear-gradient(90deg, #AD3BC5 -26.31%, #363555 47.54%, #8800A4 145.7%);

      color: #FFF;

      font-family: Gilroy;
      font-size: 19px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.855px;
      text-transform: uppercase;
      width: 475px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 27px 0;

      & span {
        color: #FFF;
        display: block;

        font-family: Gilroy;
        font-size: 38px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.9px;
        text-transform: uppercase;
      }
    }
  }

  &__button {
    position: relative;

    width: 100%;

    padding: 25px 0;

    color: #450C5C;

    text-align: center;
    font-family: Gilroy;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 1.26px;
    text-transform: uppercase;
    margin-bottom: 20px;

    border-radius: 10px;
    background: linear-gradient(90deg, #FFC700 0.91%, #FFF 58.19%, #FFC700 98.49%);
    border: none;
    outline: none;

    cursor: pointer;

    // &:disabled {
    //   opacity: .7;
    //   cursor: auto;
    //   background: #ffe380;
    // }


    &-arrow {
      position: absolute;
      bottom: -40px;
      left: -40px;

      width: 60px;
      height: 60px;
    }
  }

  &__wheel-text {
    color: #FFF;

    text-align: center;
    font-family: Gilroy;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.765px;

    & span {
      color: #FFF;
      font-family: Gilroy;
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.765px;
      text-transform: uppercase;
    }
  }

 

  &__wheel {
    position: relative;
    z-index: 98;
    top: 10%;
    right: -10%;
  }
}

@media screen and (max-width: 1230px) {
  .main {
  
    &__container {
      position: relative;
      top: 250px;
      flex-direction: column;
    }
  
    &__wheel {
      right: 0;
      top: -150px;
    }
  }
}

@media screen and (max-width: 750px) {
  .main {
  
    &__container {
      padding: 0;
      top: 180px;
      overflow: hidden;
      
    }
  
    &__text {
      padding: 0 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 50px;
      z-index: 100;
      top: 0;
    }
  
    &__title {
      font-size: 100px;
      line-height: 60px;
      max-width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;

      

  
      & span {
        font-size: 80px;
      }
    }
  
    &__subtitle {
      font-size: 25px;
      margin-bottom: 30px;
    }
  
    &__button {
      width: 80%;
    }
  
    &__wheel {
      z-index: 99;
      top: -150px;
      display: flex;
      justify-content: center;
      margin-top: 50px;
    }
  }
}

@media screen and (max-width: 500px) {
  .main__title.come-tomorrow {
    text-align: center;
    font-size: 30px;
    max-width: 300px;
  
    // width: 275px;
    

    & span {
      font-size: 25px;
    }
  }

  .main__subtitle.come-tomorrow {
    
    font-size: 12px;
  
    width: 290px;
    

    & span {
      font-size: 25px;
    }
  }
}

@media screen and (max-width: 480px) {
  .main {
  
    &__title {
      font-size: 75px;
      & span {
        font-size: 62px;
      }
    }
  
    &__subtitle {
      font-size: 19px;
    }
  
    &__button {
      font-size: 22px;
      max-width: 276px;
      width: 100%;
  
      &-arrow {
        bottom: -30px;
        left: -30px;
      }
    }
  
    &__wheel-text {
      font-size: 12px;
      max-width: 276px;
  
      & span {
        font-size: 12px;
      }
    }

    &__wheel {
      top: -210px;
      margin-top: 100px;
    }
  }
}