@keyframes rotate {
  0% {
    transform: rotate(0deg); 
  }
  100% {
    transform: rotate(1850deg);
  }
}

.wheel-fortune {
  width: 1008px;
    height: 1008px;
  margin: 0px auto;
  position: relative;
  margin-top: 95px;

  

  &__ring {
    width: 1008px;
    height: 1008px;
    border-radius: 100%;
    border-radius: 1008px;
    background: linear-gradient(180deg, #5F1A7D 0%, #3A3458 100%);

    box-shadow: 0px 4px 76.7px 0px #B79A60;



    position: relative;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    &-container {
      box-sizing: border-box;
      position: relative;
      width: 905px;
      height: 905px;
      overflow: hidden;
      border-radius: 100%;
      border: 20px solid #FFF;
      background: linear-gradient(180deg, #B9B9B9 0%, #FFF 100%);

      box-shadow: 0px 4px 40.6px 0px rgba(0, 0, 0, 0.95);
      display: flex;
      align-items: center;

      overflow: hidden;

      
      
    }
  }

  &__light {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 1015px;
    height: 1015px;
  }



  &__poligon {
    clip-path: polygon(0% 100%, 0% 0, 100% 50%);
    transform-origin: 100% 50%;
    position: relative;

    position: absolute;
    width: 50%;
    height: 42%;
    border-style: solid;
    transform-origin: 100% 50%;
 
    box-shadow: 0px 4px 40.6px 0px rgba(0, 0, 0, 0.95);

    font-size: 18px;
    font-weight: bold;
    color: #000;

    &-text {
      
      position: relative;
      top: 50%;
      left: 10%;
      z-index: 1;
      transform: translateY(-50%);
      max-width: 255px;

      color: #FFF;
      text-shadow: 0px 4px 23.399999618530273px rgba(10, 10, 10, 0.74);
   
      font-family: Gilroy;
      font-size: 24px;
      font-weight: 700;
      line-height: 120%; 

      & span {
        display: block;
        color: #FFF;
        text-shadow: 0px 4px 23.399999618530273px rgba(10, 10, 10, 0.74);
        font-family: Gilroy;
        font-size: 50px;
        font-weight: 700;
        line-height: 120%;
      }
      
    }
  }

  &__poligon:nth-child(odd) {
    background: linear-gradient(180deg, #D400FF 0%, #440052 43.17%, #D400FF 98.33%);
  }

  &__poligon:nth-child(1) { transform: rotate(90deg); }
  &__poligon:nth-child(2) { 
    transform: rotate(135deg);
    background: linear-gradient(to bottom right, #0056BB, #E5E5E5);
  }
  &__poligon:nth-child(3) { transform: rotate(180deg); }
  &__poligon:nth-child(4) { 
    transform: rotate(225deg); 
    background: linear-gradient(to top right, #32009E, white);
  }
  &__poligon:nth-child(5) { transform: rotate(270deg); }
  &__poligon:nth-child(6) { 
    transform: rotate(315deg); 
    background: linear-gradient(to bottom right, #FFC700 41.67%, #E8F3E8 66.67%,  white 100%);
  }
  &__poligon:nth-child(7) { transform: rotate(0deg); }
  &__poligon:nth-child(8) { 
    transform: rotate(45deg); 
    background: linear-gradient(to bottom right,#00A6BC 53.35%, #8d8c8c 75%, #ADADAD 100%);
  }

  &__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;

    width: 134px;
    height: 134px;
    background: linear-gradient(180deg, #FFC700 0%, #FFF 51.56%, #FFC700 100%);
    box-shadow: 0px 4px 37.599998474121094px #000;
    border-radius: 100%;
  }

  &__lights {
    position: absolute;
    top: 20%;
    left: 20%;
    transform: translate(-50%, -50%);
    width: 193px;
    height: 173px;
    z-index: 99;
  }

  &__win-area {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-48%) rotate(-5deg);
    width: 436px;
    height: 490px;
    z-index: 1;
  }

  &__fire {
    position: absolute;
    top: -190px;
    right: 40%;
  }
}

@media screen and (max-width: 1230px) {
  .wheel-fortune {
  
    &__fire {
      content: url(../../image/wheel/wheel-mobile-fire.webp);
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 1056px) {
  .wheel-fortune {
    max-width: 700px;
    max-height: 700px;
    top: 0;
    
  
    &__ring {
    max-width: 700px;
    max-height: 700px;


  
      &-container {
     
        width: 605px;
        height: 605px;
        border: 10px solid #FFF;
        
        
      }
    }
  
    &__light {
    max-width: 700px;
    max-height: 700px;
    }
  
  
  
    &__poligon {

      &-text {
        
        font-size: 20px;
  
        & span {
          font-size: 30px;
        }
        
      }
    }

    &__container {
      width: 100px;
      height: 100px;
    }
  
    &__win-area {
      width: 306px;
      height: 340px;
      top: 25px;
      transform: translateX(-48%) rotate(-3deg);
    }
  
    &__fire {
      content: url(../../image/wheel/wheel-mobile-fire.webp);
      width: 100%;
      height: 100%;
      right: auto;
    }
  }
}

@media screen and (max-width: 750px) {
  .wheel-fortune {
    max-width: 600px;
    max-height: 600px;
    top: 0;
    
  
    &__ring {
      max-width: 600px;
      max-height: 600px;


  
      &-container {
     
        width: 550px;
        height: 550px;
        border: 10px solid #FFF;
        
        
      }
    }
  
    &__light {
    max-width: 600px;
    max-height: 600px;
    }
  
  
  
    &__poligon {
  
   
  
      &-text {
        
        font-size: 18px;
  
        & span {
          font-size: 22px;
          max-width: 150px;
        }
        
      }
    }  
    
    &__container {
      width: 80px;
      height: 80px;
    }

    &__win-area {
      width: 270px;
      height: 340px;
      top: 0px;
    }
  }
}

@media screen and (max-width: 630px) {
  .wheel-fortune {
    max-width: 600px;
    max-height: 600px;
    top: 0;
    width: 100%;

    box-sizing: border-box;
  
    &__ring {
      max-width: 600px;
      max-height: 600px;

    }
  
    &__light {
    max-width: 600px;
    max-height: 600px;
    }

    &__container {
      width: 80px;
      height: 80px;
    }

    &__win-area {
      width: 270px;
      height: 340px;
      top: 0px;
    }
  }
}

@media screen and (max-width: 480px) {
  .wheel-fortune {
    max-width: 460px;
    max-height: 460px;
    width: 100%;
  
    &__ring {
      max-width: 460px;
      max-height: 460px;

      &-container {
        width: 416px;
        height: 416px;
        border: 10px solid #FFF;
        
      }
    }
  
    &__light {
    max-width: 460px;
    max-height: 460px;
    }
  
  
  
    &__poligon {

      &-text {
        
        font-size: 12px;
      }
    }
  
    &__win-area {
      width: 190px;
      height: 240px;
      top: 5px;
    }
  }
}

