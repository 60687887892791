
.button {
  border-radius: 12px;
  background: linear-gradient(104deg, #FF7E04 39.96%, rgba(255, 245, 4, 0.50) 133.03%);
  color: #fff;
  border: none;
  padding: 10px 35px;
  transition: all .3s;
  cursor: pointer;

  &:hover {
    background-color: #FF7E04;
    transition: all .3s;
  }
}

.f-size-16 {
  font-size: 16px;
  font-family: Unbounded;
}

.f-size-18 {
  font-size: 18px;
  font-family: Unbounded;
}

.f-size-20 {
  font-size: 20px;
  font-family: Unbounded;
}

.f-size-22 {
  font-size: 22px;
  font-family: Unbounded;
}

.f-size-40 {
  font-size: 40px;
  font-family: Unbounded;
}