

@font-face {
  font-family: 'Gilroy';
  // src: url('./fonts/Gilroy-ExtraBold.ttf') format('truetype');
  src: local('Gilroy ExtraBold'), local('Gilroy-ExtraBold'),
      url('./fonts/Gilroy-Extrabold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

// @font-face {
//   font-family: 'Gilroy';

//   src: local('Gilroy SemiBold'), local('Gilroy-SemiBold'),

//       url('./fonts/Gilroy-SemiBold.ttf') format('truetype');
//   font-weight: bold;
//   font-style: normal;
// }

@font-face {
  font-family: 'Gilroy';

  src: local('Gilroy Medium'), local('Gilroy-SemiBold'),
      url('./fonts/Gilroy-Medium.ttf') format('truetype');
  
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';

  src: local('Gilroy Light'), local('Gilroy-SemiBold'),

      url('./fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Gilroy", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
