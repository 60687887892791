.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 24px;
  padding-top: 50px;


  &__stars {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 90;
  }

  &__pentogram {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }


  &__blur-top {
    position: absolute;
    top: -800px;
    left: 0;
    width: 100%;
    height: 100px;
    background: #1D1334;
    z-index: 0;
    filter: blur(30.5500030518px);
  }

  &__cloud {
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
  }

  &__bg {
    background: linear-gradient(179deg, #260831 1.19%, rgba(6, 0, 8, 0.00) 135.37%);
    position: absolute;
    top: -750px;
    left: 0;
    z-index: 10;

    width: 100%;
    height: 200%;

  }

  &__list {
    position: relative;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1150px;

    margin-bottom: 134px;
  }

  & .item {
    display: flex;
    flex-direction: column;

    &__logo {
      width: 154px;
      margin-bottom: 10px;
    }

    & span {
      color: #FFF;

      font-family: Gilroy;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;

      margin-bottom: 10px;
    }

    &__header {
      color: #FFF;

      font-family: Gilroy;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; 

      margin-bottom: 20px;
    }

    &__navigation {
      color: #FFF;

      font-family: Gilroy;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 10px;

      text-decoration: none;
    }

    &__social {
      display: flex;
      justify-content: space-between;
    }
  } 

  &__creator {
    position: relative;
    z-index: 99;
    color: #FFF;

    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 142.045%;
    text-decoration: none;

    margin-bottom: 160px;
    transition: all .3s;

    &:hover {
      color: #9d9d9d;
      transition: all .3s;
    }
  }
}


@media screen and (max-width: 978px) {
  .footer {
  overflow: hidden;
  
    &__pentogram {
      height: 100%;
    }
  
  
    &__blur-top {
     top: 300px;
     filter: blur(50.5500030518px);
    }
  
    &__list {
      flex-direction: column;
      // flex-wrap: wrap;
      align-items: center;
    }
  
    & .item {
      margin-bottom: 33px;
      justify-content: center;
      align-items: center;
  
      &__social {
        align-items: center;
      //  justify-content: space-between;
        max-width: 220px;
        width: 100%;
      }
    } 
  }
}