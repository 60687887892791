.thanks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 24px;
  box-sizing: border-box;


  width: 100%;
  height: 100vh;

  &__title {
    color: #4D4D4D;

    font-family: Unbounded;
    font-size: 40px;
    font-weight: 500;
    line-height: 45px; 
    text-transform: uppercase;

    margin-bottom: 50px;
  }

  &__text {
    color: #4D4D4D;

    text-align: center;
    font-family: Unbounded;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.5px;

    margin-bottom: 50px;
    max-width: 700px;
  }

  & &__button {
    text-decoration: none;
    padding: 20px 50px;
  }
}

@media screen and (max-width: 600px) {
  .thanks {
    
  
    &__title {
      font-size: 20px
    }
  
    &__text {
      font-size: 15px;
    }
  
    & &__button {
      padding: 20px 40px;
    }
  }
  
}