.won {
  padding: 24px;
  
  position: relative;
  top: -200px;
  z-index: 99;
  background: #6C4574;

  padding-bottom: 50px;
  padding-top: 130px;

  &__bg  {
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    margin: 0 auto;
    max-height: 630px;

    & img {
      width: 100%;
      object-fit: cover;
    }
  }

  &__front {
    position: absolute;
    top: -200px;
    left: 0;
    z-index: 99;
  }

  &__right {
    position: absolute;
    right: 0;
    z-index: 99;
    top: -450px;
  }

  &__gradient {
    width: 100%;
    height: 344px;
    background: #6C4574;
    filter: blur(20.04999923706055px);
    position: absolute;
    bottom: -150px;
    
    left: 0;
  }

  // &__bg-container {
  //   position: absolute;
  //   top: -50%;
  //   // left: -50%;
  //   // transform: translateX(-50%);
  // }

  &__pentogram {
    position: relative;
    left: 0;
    z-index: 90;
    top: 0px;
    width: 100%;
    height: 100%;  
  }

  &__left-blick {
    position: absolute;
    top: 43%;
    left: 33%;
    z-index: 199;
    width: 180px;
    height: 209px;
    
  }

  &__bg-container {
    position: absolute;
    bottom: -20%;
    width: 100%;
    left: -50%;
    transform: translate(12%, 10%);
    z-index: 10;
  }

  &__black-right {
    position: absolute;
    right: 0;
    top: -100px;
    z-index: 99;
  }

  &__light-right {
    position: absolute;
    right: 0;
    top: 0px;
    z-index: 99;
  }

  &__black-left  {
    position: absolute;
    left: 0;
    top: 0px;
  }

  &__container {
    margin: 0 auto;
    max-width: 884px;
    position: relative;
    z-index: 99;

    padding: 50px 140px;

    box-sizing: border-box;

    border-radius: 10px;
    border: 1px solid #FDA900;

    background: linear-gradient(88deg, rgba(29, 14, 41, 0.43) 0.77%, rgba(29, 21, 54, 0.43) 55.2%, rgba(0, 0, 0, 0.43) 108.5%);

    backdrop-filter: blur(10px);

  }

  &__pay,
  &__start,
  &__telegram {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }

  &__title {
    color: #FFF;

    text-align: center;
    font-family: Gilroy;
    font-size: 48px;
    font-weight: 700;
    line-height: 121.6%;
    text-transform: uppercase;

    margin-bottom: 14px;
  }

  &__price {
    color: #FFC700;

    text-align: center;
    font-family: Gilroy;
    font-size: 35px;
    font-weight: 700;
    line-height: 121.6%; 
    text-transform: uppercase;
    margin-bottom: 38px;
    position: relative;

    & span {
      position: relative;

      &::before {
        content: '';

        width: 110%;
        height: 1px;
  
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
  
        background-color: #fff;
      }
      
    }
  }

  &__text {
    color: #FFF;

    text-align: center;
    font-family: Gilroy;
    font-size: 22px;
    font-weight: 400;
    line-height: 122%; 

    margin-bottom: 20px;
  }

  &__button {
    color: #FFF;

    text-shadow: 0px 0px 19.100000381469727px rgba(81, 0, 102, 0.99);
    font-family: Gilroy;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    margin-bottom: 25px;
    text-decoration: none;
    text-align: center;

    display: block;
    max-width: 475px;
    width: 100%;
    padding: 30px;

    border-radius: 10px;
    background: linear-gradient(90deg, #FFC700 -26.31%, #EB8AFF 47.54%, #FFC700 145.7%);
  }

  &__subtitle {
    color: #FFF;

    text-align: center;
    font-family: Gilroy;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 1.35px;
    text-transform: uppercase;

    margin-bottom: 50px;
  }

  &__start-img {
    height: 157px;

    margin-bottom: 50px;
  }

  &__telegram {
    & .won__button {
      background: linear-gradient(90deg, #E04CFF 0.04%, #FFF 48.18%, #E35BFF 99.8%);
      color: rgba(81, 0, 102, 0.99);
    }
  }

  &__problem {
    display: flex;
    flex-direction: column;

    & span {
      color: #FFF;

      text-align: center;
      font-family: Gilroy;
      font-size: 14px;
      font-weight: 500;
      line-height: 120%; 
      text-transform: uppercase;
    }

    & a {
      color: #FFF;

      text-align: center;
      font-family: Gilroy;
      font-size: 15px;
      font-weight: 400;
      line-height: 120%; 
      text-decoration-line: underline;
    }
  }
}

// @media screen and (max-width: 1160px) {
//   .won__bg-container {
//     position: absolute;
//     top: -50%;
//     width: 100%;
//     left: -50%;
//     transform: translate(12%, 50%);
//     z-index: 10;
//   }
// }

// @media screen and (max-width: 1012px) {
//   .won__bg-container {
//     position: absolute;
//     top: -50%;
//     width: 100%;
//     left: -50%;
//     transform: translate(12%, 70%);
//     z-index: 10;
//   }
// }


@media screen and (max-width: 764px) {
  .won {
    padding: 24px;
  
    &__front {
      left: -200px;
    }
  
    &__right {
      width: 50%;
      top: -200px;
    }
  
    &__title {
      font-size: 40px;
    }
  
    &__button {
      padding: 15px;
    }
  
    &__subtitle {
      font-size: 25px;
    }
  }
}

@media screen and (max-width: 662px) {
  .won {
  
    &__front {
      left: -200px;
    }
  
    &__right {
      width: 50%;
      top: -200px;
    }
  
    &__container {
      padding: 40px;
  
    }
  
    &__title {
      font-size: 40px;
    }
  
    &__button {
      padding: 15px;
    }
  
    &__subtitle {
      font-size: 25px;
    }
  }
}

@media screen and (max-width: 522px) {
  .won {

    &__left-blick {
      width: 80px;
      height: 129px;
  }
  
    &__title {
      font-size: 25px;
    }
  
    &__price {
      font-size: 20px;
    }
  
    &__text {
      font-size: 10px;
    }
  
    &__button {
      max-width: 277px;
      font-size: 19px;
    }
  
    &__problem {

  
      & span {
        font-size: 10px;
      }
  
      & a {
        font-size: 10px;
      }
    }
  }
}