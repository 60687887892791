.timer {
  position: relative;
  z-index: 200;


  &__pentacles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 98;
  }

  &__red-light {
    position: absolute;
    top: -300px;
    right: 0;
    z-index: 4;
  }

  &__blur-top {
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background: #1D1334;
    z-index: 99;
    filter: blur(30.5500030518px);
  }

  &__blur-bottom {
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background: #1D1334;
    z-index: 99;
    filter: blur(30.5500030518px);
  }


  

  &__bg {
    position: absolute;
    z-index: 50;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: rgba(29, 19, 52, 0.66);

    filter: blur(109.55000305175781px);
  }

  &__container {
    max-width: 1140px;
    margin: 0 auto;
    position: relative;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px;

    padding-bottom: 66px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.17);
    
  }

  &__title {
    text-align: center;
    font-family: Gilroy;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;

    background: linear-gradient(93deg, #FFC700 -16.45%, #FFF 54.84%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    & span {
      display: block;
      font-family: Gilroy;
      font-size: 49px;
      font-weight: 700;
      line-height: 109.1%; 
      letter-spacing: 0.49px;
      text-transform: uppercase;
    }
    margin-bottom: 7px;
  }

  &__subtitle {
    text-align: center;
    font-family: Gilroy;
    font-size: 30px;
    font-weight: 700;
    line-height: 140%;
    text-transform: uppercase;

    margin-bottom: 50px;

    background: linear-gradient(93deg, #FFF -1.18%, #FFF 92.19%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__countdown {
    margin-bottom: 50px;
  }

  &__shance {
    text-align: center;
    font-family: Gilroy;
    font-size: 45px;
    font-weight: 700;
    line-height: 140%; 
    text-transform: uppercase;

    margin-bottom: 10px;

    background: linear-gradient(93deg, #FFE600 -4.44%, #FFF 73.77%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }


  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: linear-gradient(90deg, #FFC700 0.91%, #FFF 58.19%, #FFC700 98.49%);
    max-width: 343px;
    width: 100%;
    height: 86px;

    text-decoration: none;

    color: #211738;

    text-align: center;
    font-family: Gilroy;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 1.08px;
    text-transform: uppercase;

    margin-bottom: 28px;
  }

  &__text {
    color: #FFF;

    text-align: center;
    font-family: Gilroy;
    font-size: 20px;
    font-weight: 400;
    line-height: 120%;

    max-width: 500px;
  }

  &__countdown {
    width: 100%;
  }

  & .countdown {
    display: flex;
    justify-content: space-between;

    align-items: center;
    width: 100%;

    &__item {
      font-family: Gilroy;
      font-size: 150px;
      font-weight: 700;
      line-height: 120%;
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      min-width: 180px;
      max-width: 208px;
      width: 100%;

      border-radius: 10px;
      border: 1px solid #FFC700;

      background: linear-gradient(88deg, rgba(0, 0, 0, 0.10) 0.77%, rgba(35, 35, 35, 0.10) 55.2%, rgba(0, 0, 0, 0.10) 108.5%);

      box-shadow: 0px 0px 22.9px 0px rgba(255, 199, 0, 0.17);
      backdrop-filter: blur(10px);

      background: linear-gradient(90deg, #FFC700 0.91%, #FFF 58.19%, #FFC700 98.49%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      & span {
        display: block;
        text-align: center;
        font-family: Gilroy;
        font-size: 25px;
        font-weight: 700;
        line-height: 140%; 
        text-transform: uppercase;
       
        background: linear-gradient(93deg, #FFC700 -1.18%, #FFF 92.19%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

}


@media screen and (max-width: 1060px) {
  .timer {

    &__pentacles {
      object-fit: cover;
    }

    & .countdown {

  
      &__item {
        padding: 10px;
  
      }
    }
  }
}

@media screen and (max-width: 899px) {
  .timer {
  
    & .countdown {

  
      &__item {
        font-size: 100px;
        min-width: 150px;
      }
    }
  
  }
}

@media screen and (max-width: 753px) {
  .timer {
  
    & .countdown {

  
      &__item {
        font-size: 70px;
        min-width: 100px;
  
      }
    }
  }
}

@media screen and (max-width: 577px) {
  .timer {
  
    &__title {
      font-size: 30px;
  
      & span {
        font-size: 22px;
      }

    }
  
    &__subtitle {
      font-size: 16px;
    }
  
    &__shance {
      font-size: 20px;
    }
  
  
    &__button {
      width: 275px;
      height: 60px;
      font-size: 22px;
    }
  
    &__text {
      font-size: 12px;
      max-width: 260px;
    }
  
    & .countdown {

  
      &__item {
        font-size: 50px;
        min-width: auto;
        padding: 10px 5px;
        width: 76px;
        height: 103px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
  
        & span {
          font-size: 12px;
        }
      }
    }
  
  }
}