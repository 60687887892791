.header {
  max-width: 1160px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 500;
  
}



.nav {
  position: relative;
  z-index: 99;
  
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;

  &__logo {
    width: 154px;
    height: 44px;
  }

  &__list {
    max-width: 390px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    width: 100%;
  }

  &__link {
    
    color: #FFF;

    font-family: Gilroy;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    transition: all .3s;

    &:hover {
      color: #6C4574;
      transition: all .3s;
    }
  }
}

@keyframes showMenu {
  0% {
    height: 0;
    transition: all .5s;
  } 
  100% {
    height: calc(100vh - 120px);
    transition: all .5s;
  }
}

.header__mobile {
  display: none;
  background: #200929;
  

  & .nav-mobile-menu {
    position: fixed;
    top: 100px;
    left: 0;
    // height: calc(100vh - 120px);
    width: 100%;
    height: 100%;
    max-width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    // max-width: 0;

  
    animation-name: showMenu;
    animation-duration: .3s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;

    
    & .nav__list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 25px;
      background: #200929;
      max-width: 100%;
      width: 100%;
      height: 105%;
    }

    & .nav__item {
      margin-bottom: 25px;


    }

  }
}


.order {

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    width: 100%;

    & img {
      width: 154px;
    }
  }

  &__item-icon {
    display: block;
    width: 40px;
    height: 40px;
    // background-image: url(../../image/icons/insta.webp);
    background-repeat: no-repeat;
    background-position: center;
    transition: all .3s;
    background-size: 40px;
    cursor: pointer;
  
    &:hover {
      // background-image: url(../../image/icons/insta-hover.webp);
      transition: all .3s;
    }
  }

  &__link {
    text-decoration: none;
    color: #3D3D3D;

    font-family: Unbounded;
    font-size: 18px;
    font-weight: 600;
    transition: all .3s;

    &:hover {
      color: #FF7E04;
      transition: all .3s;
    }
  }
 
}




@media screen and (max-width: 980px) {
  .nav {
    // max-width: 400px;
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .nav {
    // max-width: 350px;
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .header.desktop {
    display: none;
  }

  .header__mobile {
    display: flex;
    background-color: transparent;

    & .order__list {
      max-width: 100%;
    }
  }


  


}


@media screen and (max-width: 500px) {

  .header__mobile {

    & .order__link {
      font-size: 12px;
    }

  }


  


}