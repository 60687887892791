.for-whom {
  position: relative;
  // z-index: 200;
  min-height: 850px;
  z-index: 90;
  
  &____left-pink,
  &____right-pink {
    position: absolute;
    display: none;
  }

  &__black-left {
    position: absolute;
    transform: rotate(180deg);
    left: 0;
    bottom: -500px;
  }

  &__black-right {
    position: absolute;
    left: 0;
    bottom: -700px;
    z-index: 99;
    width: 100%;
    display: none;
  }

  &__planet {
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translate(-50%);
    z-index: 1;
    width: 100%;
    margin: 0 auto;
    // height: 100%;
    object-fit: cover;
  }

  &__black-right-two-container {
    display: none;
  }

  &__stars-bg {
    position: absolute;
    z-index: 2;
    top: -50%;
    width: 100%;
    height: 300%;
    left: 50%;
    transform: translate(-50%);
  }

  &__mentor {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translate(50%);
    z-index: 90;
  }

  &__mentor-container {
    display: none;
  }

  &__black-right-two {
   display: none; 
  }

  &__black-top {
    position: absolute;
    top: -170%;
    left: 50%;
    transform: translate(-50%);
    z-index: 91;
    display: none;
  }

  &__black-right {
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 90;
    transform: rotate(180deg);
  }

  &__container {
    max-width: 1160px;
    margin: 0 auto;
    position: relative;
    z-index: 99;
    padding: 0 24px;
   
  }

  &__title {
    text-align: center;
    font-family: Gilroy;
    font-size: 48px;
    font-weight: 700;
    line-height: 121.6%; 
    text-transform: uppercase;
    background: linear-gradient(93deg, #FFF -1.18%, #FFF 92.19%);
    background-clip: text;
    color: transparent;
    margin-bottom: 51px;
  }

  &__container {
    display: flex;
    // flex-wrap: wrap;
    flex-direction: column;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__item {
    padding-top: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.17);
    max-width: 460px;
    width: 100%;
    margin-bottom: 20px;

    &-header {
      display: flex;
      align-items: center;

      &-index {
        font-family: Gilroy;
        font-size: 60px;
        font-weight: 700;
        line-height: 120%;
        background: linear-gradient(128deg, #FFF -6.1%, #FFC700 73.51%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-right: 10px;
      }

      &-title {
        color: #FFF;

        font-family: Gilroy;
        font-size: 20px;
        font-weight: 700;
        line-height: 120%; 
        text-transform: uppercase;
        background: linear-gradient(128deg, #FFF -6.1%, #FFC700 73.51%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &-text {
      margin-left: 70px;
      color: #FFF;

      font-family: Gilroy;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      margin-bottom: 18px;
      position: relative;


      & span {
        display: block;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background-color: #FFC700;
        position: absolute;
        top: 50%;
        left: -40px;
        transform: translateY(-50%);
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .for-whom {
    
  
    &__planet {
      // height: 100%;
      object-fit: cover;
    }

  
    &__mentor {
      max-width: 483px;
      top: 100px
    }
  
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      
     
    }
  
    &__title {
      max-width: 700px;
    }
  
    &__item {
      max-width: 360px;
  
    }
  }
}

@media screen and (max-width: 767px) {
  .for-whom {
  
    &__planet {
      height: 100%;
      object-fit: cover;

    }

  
    &__left-pink {
      position: absolute;
      display: block;
      top: 600px;
      width: 100%;
      height: 200px;
      z-index: 90;
    }

    &__right-pink {
      position: absolute;
      display: block;
      top: 0px;
      width: 100%;
      height: 200px;
      z-index: 199;
    }

    &__mentor {
      max-width: 60%;
      top: 100px;
      right: 50%;
      transform: translateX(82%);
    }

    &__black-right-two {
      display: block;
      position: absolute;
      bottom: -800px;
      right: 0;
      z-index: 90;
      width: 100%;
    }
  
    &__black-right {
      bottom: 200px;
    }
  
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 99;
    }
  
    &__title {
      max-width: 700px;
    }
  
    &__item {
      max-width: 360px;

      &:nth-child(2) {
        order: 3
      }
      &:nth-child(3) {
        order: 2
      }
      &:nth-child(4) {
        order: 4
      }
  
    }
  }
}


@media screen and (max-width: 665px) {
  .for-whom {
    
    &__mentor {
      width: 50%;
      right: 0;
      transform: translateX(0%);

      top: 300px;
    }

    &__black-right-two {
      display: block;
      position: absolute;
      bottom: -800px;
      right: 0;
      z-index: 90;
      width: 100%;

    }
  
    &__black-right {
      bottom: 200px;
    }
  
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 99;
    }
  
    &__title {
      max-width: 700px;
    }

  
    &__item {
      max-width: 360px;
    }
  }
}


@media screen and (max-width: 635px) {
  .for-whom {

    &__mentor {
      width: 40%;
      right: 0;
      transform: translateX(0%);

      top: 300px;
    }

    &__black-right-two {
      display: block;
      position: absolute;
      bottom: -600px;
      right: 0;
      z-index: 90;
      width: 100%;
    }
  
    &__black-right {
      bottom: 200px;
    }
  
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 99;
    }
  
    &__title {
      max-width: 700px;
    }
  
    &__item {
      max-width: 360px;

    }
  }
}

@media screen and (max-width: 551px) {
  .for-whom {

    &__mentor {
      
      display: none;
    }

    &__mentor-container {
      position: absolute;
      display: block;
      width: 60%;
      right: 0;
      transform: translateX(30%);
      overflow: hidden;
      z-index: 9;
      top: 300px;
    }

    &__black-right-two {
      display: block;
      position: absolute;
      bottom: -400px;
      right: 0;
      z-index: 90;
      width: 100%;
     
    }
  
    &__black-right-two-container {
      display: block;
      position: absolute;
      z-index: 91;
      top: 250px;
      right: 0;
    }
  
    &__black-right {
      bottom: 200px;
    }
  
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 99;
      overflow: hidden;
    }
  
    &__title {
      font-size: 22px;
    }

    &__list {
      position: relative;
      z-index: 99;
    }
  
    &__item {
      max-width: 250px;

  
      &-header {
      
  
        &-index {
          font-size: 30px;
        }
  
        &-title {
          font-size: 15px;
        }
      }
  
      &-text {

        font-size: 12px;
      }
    }
  }
}