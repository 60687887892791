.first {
  position: relative;
  z-index: 200;
  margin-bottom: 93px;

  &__front-front {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
  }

  &__blur-bottom {
    position: absolute;
    bottom: -30px;
    left: 0;
    width: 100%;
    height: 100px;

    background: #6C4574;

    filter: blur(31.04999923706055px);
  }

  &__front-left-top {
    position: absolute;
    left: 0;
    top: 300px;
    width: 100%;
    object-fit: cover;
    display: none;
    transform: rotate(180deg);
  }

  &__front-right-top {
    position: absolute;
    right: 0;
    top: 30%;
    width: 100%;
    object-fit: cover;
    display: none;
    transform: rotate(180deg);
  }

  &__front-right-bottom {
    position: absolute;
    left: 0;
    bottom: 30%;
    width: 100%;
    object-fit: cover;
    display: none;
  }

  &__front-left-bottom {
    position: absolute;
    right: 0;
    bottom: 200px;
    width: 100%;
    object-fit: cover;
    display: none;
  }


  &__left-pink {
    width: 100%;
    object-fit: cover;
    position: absolute;

    transform: rotate(180deg);
    
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__blur {
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    height: 700px;

    background: #6C4574;

    filter: blur(51.04999923706055px);
  }

  &__yelow {
    position: absolute;
    bottom: -350px;
    right: 0;
    z-index: 90;
  }

  &__pentacles {
    position: absolute;
    bottom: -520px;
    left: -40%;
    z-index: 90;
    width: 100%;
  }

  &__left-pink {
    position: absolute;
    bottom: 200px;
    left: 0;
    z-index: 95;
  }


  

  &__container {
    position: relative;
    padding: 0 24px;
    padding-top: 214px;

    max-width: 1160px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 99;
    
  }

  &__title {
    color: #FFC700;

    text-align: center;
    text-shadow: 0px 4px 15.600000381469727px rgba(255, 170, 0, 0.49);
    font-family: Gilroy;
    font-size: 55px;
    font-weight: 700;
    line-height: 109.1%;
    letter-spacing: 0.55px;
    text-transform: uppercase;

    margin-bottom: 30px;

    max-width: 993px;
    width: 100%;


    & span {
      color: #FFC700;
      font-family: Gilroy;
      font-size: 70px;
      font-weight: 700;
      line-height: 109.1%;
      letter-spacing: 0.7px;
      text-transform: uppercase;
    }
  }

  &__subtitle {
    text-align: center;
    text-shadow: 0px 4px 14px rgba(255, 199, 0, 0.56);
    font-family: Gilroy;
    font-size: 34px;
    font-weight: 700;
    line-height: 109.1%;
    letter-spacing: 0.34px;
    text-transform: uppercase;

    max-width: 726px;

    background: linear-gradient(93deg, #FFF -1.18%, #FFF 92.19%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    margin-bottom: 73px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 106px;
  }

  & .item {
    max-width: 279px;
    width: 100%;
    height: 378px;
    box-sizing: border-box;

    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    border-radius: 10px;
    border: 1px solid #FDA900;

    background: linear-gradient(88deg, rgba(29, 14, 41, 0.55) 0.77%, rgba(29, 21, 54, 0.55) 55.2%, rgba(0, 0, 0, 0.55) 108.5%);

    backdrop-filter: blur(10px);

    padding: 9px 10px;

    &__title {
      color: #FFC700;
      height: 100px;

      text-align: center;
      font-family: Gilroy;
      font-size: 16px;
      font-weight: 700;
      line-height: 25px; 
      text-transform: uppercase;

      margin-bottom: 10px;
    }

    &__description {
      width: 241px;
      height: 69px;
      box-sizing: border-box;
      padding: 10px 20px;
      display: flex;
      align-items: center;

      color: #FFF;

      text-align: center;
      font-family: Gilroy;
      font-size: 9px;
      font-weight: 500;
      line-height: 120%; 

      border-radius: 10px;
      background: linear-gradient(88deg, rgba(29, 14, 41, 0.29) 0.77%, rgba(29, 21, 54, 0.29) 55.2%, rgba(0, 0, 0, 0.29) 108.5%);

      backdrop-filter: blur(10px);

      margin-bottom: 25px;

      &.teleg {

       margin-bottom: 5px;
      }
    }

    &__old-price {
      display: block;
      text-align: center;
      font-family: Gilroy;
      font-size: 35px;
      font-weight: 700;
      line-height: 121.6%;
      text-transform: uppercase;

      background: linear-gradient(84deg, #D400FF -4.05%, #FFC700 71.67%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      text-decoration: line-through;

      margin-bottom: 33px;

      position: relative;

      &::before {
        content: '';

        width: 110px;
        height: 1px;
        background-color: #fff;

        position: absolute;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

      }

      &.teleg {
        margin-bottom: 3px;
      }
    }

    &__new-price {
      border-radius: 10px;
      background: linear-gradient(90deg, #FFC700 -26.31%, #EB8AFF 47.54%, #FFC700 145.7%);
      display: block;
      width: 202.547px;
      height: 51.614px;

      font-family: Gilroy;
      font-size: 40px;
      font-weight: 700;
      line-height: 121.6%;
      text-transform: uppercase;

      // background: linear-gradient(86deg, #FFF 4.41%, #FFF 72.7%);
      color: #fff;
      // background-clip: text;
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
      text-decoration: none;

      display: flex;
      align-items: center;
      justify-content: center;

      margin-bottom: 21px;

      &.teleg {

        text-align: center;
        font-family: Gilroy;
        font-size: 43px;
        font-weight: 700;
        line-height: 20.6%; 
        text-transform: uppercase;
        background: linear-gradient(84deg, #D400FF -4.05%, #FFC700 71.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        margin-bottom: 0px;
      }
    }

    &__support-teleg {
      color: rgba(81, 0, 102, 0.99);

      text-align: center;
      font-family: Gilroy;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 18px */
      text-transform: uppercase;
      width: 202.547px;
      height: 51.614px;
      border-radius: 10px;
      background: linear-gradient(90deg, #E04CFF 0.04%, #FFF 48.18%, #E35BFF 99.8%);
      text-decoration: none;
      display: flex;
      align-items: center;

      margin-bottom: 21px;
    }

    &__problem {
      color: #FFF;

      text-align: center;
      font-family: Gilroy;
      font-size: 10px;
      font-weight: 500;
      line-height: 120%; 
      text-transform: uppercase;
    }

    &__support {
      color: #FFF;

      text-align: center;
      font-family: Gilroy;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; 
      text-decoration-line: underline;
    }
  }


  &__text {
    color: #FFF;

    text-align: center;
    text-shadow: 0px 4px 15.600000381469727px rgba(255, 170, 0, 0.49);
    font-family: Gilroy;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 109.1%; /* 27.275px */
    letter-spacing: 0.25px;
    text-transform: uppercase;

    max-width: 979px;
    padding: 30px 80px;
    box-sizing: border-box;

    border-radius: 10px;
    border: 1px solid #FDA900;

    background: linear-gradient(88deg, rgba(29, 14, 41, 0.43) 0.77%, rgba(29, 21, 54, 0.43) 55.2%, rgba(0, 0, 0, 0.43) 108.5%);

    backdrop-filter: blur(10px);

    margin-bottom: 22px;
  }

  &__write {
    color: #FFF;

    text-align: center;
    font-family: Gilroy;
    font-size: 18px;
    font-weight: 400;
    line-height: 120%; 
    text-decoration-line: underline;
  }
}

@media screen and (max-width: 884px) {
  .first {

    &__pentacles {
      bottom: -220px;
    }

    &__front-left-top {
      position: absolute;
      left: 0;
      top: 300px;
      width: 100%;
      object-fit: cover;
      display: block;
    }
  
    &__front-right-top {
      position: absolute;
      right: 0;
      top: 30%;
      width: 100%;
      object-fit: cover;
      display: block;
    }
  
    &__front-right-bottom {
      position: absolute;
      left: 0;
      bottom: 30%;
      width: 100%;
      object-fit: cover;
      display: block;
    }
  
    &__front-left-bottom {
      position: absolute;
      right: 0;
      bottom: 200px;
      width: 100%;
      object-fit: cover;
      display: block;
    }
  
    &__list {
      justify-content: space-around;
    }
  }
}

@media screen and (max-width: 600px) {
  .first {
  
    &__container {

      padding-top: 150px;
    }
  
    &__title {
      font-size: 35px;
  
  
      & span {
        font-size: 45px;
      }
    }
  
    &__subtitle {
      font-size: 25px;
    }
  
    &__list {
      margin-bottom: 70px;
    }
    
    &__text {
      padding: 25px 50px;
      font-size: 22px;
    }
  }
}

@media screen and (max-width: 500px) {
  .first {

  
    &__pentacles {
      bottom: -100px;
    }

     &__front-front {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
    }

    &__front-left-top {
      position: absolute;
      left: 0;
      top: 300px;
      width: 100%;
      object-fit: cover;
    }

    &__front-right-top {
      position: absolute;
      right: 0;
      top: 30%;
      width: 100%;
      object-fit: cover;
    }

    &__front-right-bottom {
      position: absolute;
      left: 0;
      bottom: 30%;
      width: 100%;
      object-fit: cover;
    }

    &__front-left-bottom {
      position: absolute;
      right: 0;
      bottom: 200px;
      width: 100%;
      object-fit: cover;
    }


    &__left-pink {
      width: 100%;
      object-fit: cover;
      position: absolute;

      transform: rotate(180deg);

    }
  
    &__title {
     
      font-size: 24px;
  
      & span {
        font-size: 30px;
      }
    }
  
    &__text {
      font-size: 18px;
    }
  
    &__write {
      font-size: 15px;

    }
  }
}