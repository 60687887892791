.revies {
  // height: 100vh;

  margin-bottom: 100px;
  

  position: relative;
  z-index: 100;

  &__bg {
    width: 90%;
    height: 100%;
    background: #1D1334;
    position: absolute;
    z-index: 1;

    filter: blur(109.55000305175781px);
  }

  &__blick {
    position: absolute;
    right: 0%;
    top: 30%;
    opacity: .4;
  }

  &__front-cloud {
    position: absolute;
    top: -500px;
    left: 0;
    // transform: translate(-50%);
    z-index: 100;
  }


  &__container {
    box-sizing: border-box;
    position: relative;
    top: -50px;
    z-index: 99;
    padding: 90px 75px;
    padding-bottom: 40px;
    max-width: 1098px;
    margin: 0 auto;
    border-radius: 10px;
    border: 1px solid #FDA900;

    background: linear-gradient(88deg, rgba(29, 14, 41, 0.80) 0.77%, rgba(29, 21, 54, 0.80) 55.2%, rgba(0, 0, 0, 0.80) 108.5%);

    backdrop-filter: blur(10px);
  }

  &__title {
    text-align: center;
    font-family: Gilroy;
    font-size: 30px;
    font-weight: 700;
    line-height: 121.6%; 
    text-transform: uppercase;

    background: linear-gradient(93deg, #B9B9B9 -1.18%, #FFF 92.19%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    margin-bottom: 40px;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  & .item {
    max-width: 426px;
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;

    &__text {
      background-image: url(../../image/revies/revie-box.webp);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;

      padding: 0px 10px;


      // padding: 40px;
      // padding-bottom: 50px;

      max-width: 426.754px;
      width: 100%;
      height: 215px;

      color: #FFF;

      font-family: Inter;
      font-size: 11px;
      font-weight: 500;
      letter-spacing: 0.33px;

      display: flex;
      justify-content: center;
      align-items: flex-start;

      & span {
        max-width: 368px;
        // padding-top: 10px;
        height: 90%;
        display: flex;
        align-items: center;
      }
    }

    &__container {
      display: flex;
      align-items: center;

      & img {
        margin-right: 10px;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
    }

    &__name {
      color: #FFF;

      font-family: Inter;
      font-size: 20px;
      font-weight: 600;
      line-height: 121.3%;
    }
    
    &__insta {
      color: #FFF;

      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 121.3%; 
    }
  }


}

@media screen and (max-width: 927px) {
  .revies {
    padding: 24px;

    &__list {
      justify-content: center;
      
    }  
  }
}

@media screen and (max-width: 767px) {
  .revies {
    margin-top: 300px;
    
  
    &__bg {
      width: 100%;
      display: none;
    }
  
    &__front-cloud {
      width: 100%;
    }
  
   
  }
}

@media screen and (max-width: 591px) {
  .revies {
    margin-top: 150px;
  
   
    &__front-cloud {
      top: -330px;
    }
  
  
    &__container {
      padding: 24px;
      top: 70px;
    }
  
    &__title {
      font-size: 16px;
    }
  
    & .item {

  
      &__text {
   
        padding: 20px;
        box-sizing: border-box;
        background-size: 95% 100%;
  
        & span {
          font-size: 8px;
          max-width: 300px;
        }
      }
  
      &__name {
        font-size: 12px;
      }
      
      &__insta {
        font-size: 10px;
      }
    }
  
  
  }
}

@media screen and (max-width: 450px) {
  .revies {
    margin-top: 150px;
  
   
    &__front-cloud {
      top: -270px;
    }
  
  
    &__container {
      padding: 24px;
      top: 0px;
    }
  
    &__title {
      font-size: 16px;
    }
  
    & .item {

  
      &__text {
   
        padding: 20px;
        box-sizing: border-box;
        background-size: 95% 100%;
  
        & span {
          font-size: 8px;
          max-width: 300px;
        }
      }
  
      &__name {
        font-size: 12px;
      }
      
      &__insta {
        font-size: 10px;
      }
    }
  
  
  }
}