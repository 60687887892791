.faq {
  position: relative;
  z-index: 201;
  padding: 24px;

  margin-bottom: 0px;
  
  // max-height: 1400px;
  height: 100%;
  overflow: hidden;

  padding-top: 25px;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
  &__left-pink {
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: -150px;
  }

  &__light {
    position: absolute;
    top: -300px;
    right: 0;
    z-index: 4;
  }

  &__cloud {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
  }
  

  .faq__container {
    max-width: 1165px;
    padding: 24px;
    margin: 0 auto;
    position: relative;
    z-index: 99;
    padding-bottom: 70px;

    display: flex;
    flex-direction: column;
    align-items: center;

    border-bottom: 1px solid rgba(255, 255, 255, 0.17);
    
  }





  &__title {
    color: #FFF;

    text-align: center;
    font-family: Gilroy;
    font-size: 48px;
    font-weight: 700;
    line-height: 121.6%; 
    text-transform: uppercase;
    margin-bottom: 28px;
  }

  & &__accordion {
    position: relative;
    z-index: 99;
    border: none;

    box-shadow: none;
    
    background-color: transparent;
    margin-bottom: 20px;

    color: #FFF;

    font-family: Gilroy;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;

    border-radius: 10px;    

    &::before {
      display: none;
    }
  }

  & &__summary {


    .MuiAccordionSummary-content {
      display: flex;
      align-items: center;
      height: 50px;
    }
    

    border-radius: 10px;
    border: 1px solid #FDA900;

    background: linear-gradient(88deg, rgba(29, 14, 41, 0.08) 0.77%, rgba(29, 21, 54, 0.08) 55.2%, rgba(0, 0, 0, 0.08) 108.5%);

    backdrop-filter: blur(10px);

    color: #FFF;;

    font-family: Gilroy;
    font-size: 20px;
    font-weight: 700;
    line-height: 100%;

    & .accourdion__icon {
      display: block;
      width: 19px;
      height: 19px;
      border-radius: 50%;

      background-color: #FFC700;
      margin-right: 20px;
    }

    & .accourdian__text {
      max-width: 1018px;
    }
  }

  & .accourdian__support-link {
    color: #fff;
  }

  &__button {
    text-decoration: none;

    margin-top: 88px;
    border-radius: 10px;
    background: linear-gradient(90deg, #FFC700 -26.31%, #EB8AFF 47.54%, #FFC700 145.7%);
 
    display: flex;
    align-items: center;
    justify-content: center;

    color: #220D39;

    font-family: Gilroy;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;

    width: 420px;
    height: 76px;
  }
}

@media screen and (max-width: 720px) {

  .faq {
  
    &__title {
      color: #FFF;

      text-align: center;
      font-family: Gilroy;
      font-size: 25px;
      font-weight: 700;
      line-height: 121.6%;
      text-transform: uppercase;

      margin-bottom: 60px;
    }
  
    & &__summary {
      color: #FFF;

      font-size: 14px;
      font-weight: 600;
      line-height: 125%;
    }

  }

  .faq {

    &__title {
      color: #FFF;

      text-align: center;
      font-family: Gilroy;
      font-size: 25px;
      font-weight: 700;
      line-height: 121.6%;
      text-transform: uppercase;

      margin-bottom: 60px;
      
    }


    & &__summary {

      & .accourdian__text {
        max-width: 400px;
      }
    }

  }
}

@media screen and (max-width: 592px) {

  .faq {
  
    &__title {
      font-size: 25px;
    }
  
    & &__summary {
      font-size: 11px;
    }
  
    & &__details {
      font-size: 15px;
      
    }
  }

  .faq {

    & &__summary {

      & .accourdian__text {
        max-width: 300px;
      }
    }

    
  }
}

@media screen and (max-width: 500px) {



  .faq {

    & &__summary {

      & .accourdion__icon {
        max-width: 19px;
        width: 100%;
      }

      & .accourdian__text {
        max-width: 250px;
      }
    }

    &__button {
      width: 276px;
      height: 54px;
      font-size: 17px;
    }
  }
}

@media screen and (max-width: 390px) {

  .faq {
  
    & &__summary {
      font-size: 10px;
    }

  }

  .faq {


    & &__summary {   

      & .accourdion__icon {
        max-width: 19px;
        width: 100%;
      }

      & .accourdian__text {
        max-width: 250px;
      }
    }

  }
}